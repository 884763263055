import React from "react"
import styled from "styled-components"
import { colors, mediaQuery } from "../../theme"
import { ReactComponent as LogoSvg } from "../../images/logo-green.svg"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"

const FooterWrapper = styled.div`
  margin-top: 128px;
  width: 100%;
  height: 180px;
  background: ${colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const CopyrightText = styled.span`
  color: ${colors.gray300};
  font-size: 14px;
  line-height: 1.7;
  margin-top: 16px;
  font-family: Manrope;

  ${mediaQuery("sm")} {
    font-size: 12px;
  }
`

export const Footer = () => {
  const { t } = useTranslation()
  return (
    <FooterWrapper>
      <LogoSvg width={80} />
      <CopyrightText>
        &copy; {new Date().getFullYear()} EDIH4LT {t("footer.rights")}
      </CopyrightText>
    </FooterWrapper>
  )
}
