export const colors = {
  white: "#FFFFFF",
  black: "#2D2D2D",
  black100: "#231f20",
  gray: "#F5F5F5",
  gray50: "#E6E6E6",
  gray100: "#CCCCCC",
  gray300: "#D9DBE1",
  gray900: "#474A57",
  green50: "#65C96E",
  green100: "#39B54A",
}
