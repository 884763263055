import { useI18next } from 'gatsby-plugin-react-i18next';
import { useLocation } from "@reach/router"

const useLink = () => {
  const { language } = useI18next();


  const getLink = (url) => {

    if (language === 'en') {
      return `${url}`;
    } else if (language === 'lt') {
      return `lt/${url}`;
    } else {
      return `${language}/${url}`;
    }
  };

  return { getLink };
};

export { useLink };
