import React from "react"
import { ReactComponent as LogoSvg } from "../../images/logo-dark.svg"
import styled from "styled-components"
import { colors, mediaQuery } from "../../theme"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Container } from "../../theme"
import { Link, navigate } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { useLink } from "../../core/useLink"
import { useLocation } from "@reach/router"
import { ReactComponent as FlagLT } from "../../images/lt.svg";
import { ReactComponent as FlagEN } from "../../images/en.svg";

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 32px 0 24px;
`

const HeaderNavigation = styled.div`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  a {
    text-decoration: none;
    margin: 0 16px;
    color: ${colors.gray900};
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;

    &:hover {
      color: ${colors.green100};
      cursor: pointer;
    }
    ${mediaQuery("sm")} {
      margin: 0 8px;
      font-size: 11px;
      line-height: 1.5;
    }
  }
`

const Button = styled.div`
  display: flex;
  height: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  cursor: pointer;

`

const LINKS = [
  {
    to: "#about-us",
    translationKey: "header.aboutUs",
  },
  {
    to: "#members",
    translationKey: "header.members",
  },
  {
    to: "#services",
    translationKey: "header.services",
  },
  {
    to: "#news",
    translationKey: "header.news",
  },
  {
    to: "#events",
    translationKey: "header.events",
  },
  {
    to: "#contact-us",
    translationKey: "header.contactUs",
  },
]

export const Header = ({ eventsExists, newsExists }) => {
  const { t} = useTranslation() 
  const { language } = useI18next();
  const { getLink } = useLink()

  const languageSwitcher = () => {
    const flag = language === "lt" ? <FlagEN /> : <FlagLT />

    return (
      <Button
        type="button"
        onClick={() => {
          navigate(language === "lt" ? "/" : "/lt");
        }}
      >
        {flag}
      </Button>
        
    )
  }


  const renderLink = (to, children) => {
    let link = "/" + getLink(to)

    return <Link to={link}>{children}</Link>
  }

  return (
    <Container>
      <HeaderWrapper>
        <Link to="/">
          <LogoSvg width={70} />
        </Link>
        <HeaderNavigation>
          {LINKS.map(({ to, translationKey }) => {
            if (to === "#events" && !eventsExists) return null;
            if (to === "#news" && !newsExists) return null;

            return (
              <React.Fragment key={to}>
                {renderLink(to, t(translationKey))}
              </React.Fragment>
            )
          })}

          {languageSwitcher()}
        </HeaderNavigation>
      </HeaderWrapper>
    </Container>
  )
}
